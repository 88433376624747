
<template>
  <div>
    <div style="height:60px;"></div>
    <a-form  :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="新的密码" required>
        <a-input-password v-model="form.password" style="width:200px;"></a-input-password>
      </a-form-item>
      <a-form-item label="确认密码" required>
        <a-input-password v-model="form.repassword" style="width:200px;"></a-input-password>
      </a-form-item>
      <a-form-item style="text-align: center;">
        <a-button type="primary" @click="handleSubmit()">修改密码</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>

  export default {
    // components: { staffInput },
    data () {
      return {
        title: '修改密码',
        apiUrl: this.$api.manage, // 依据实际前端接口目录修改接口地址
        list: [],
        modalShow: false,
        form: {
          password: '',
          repassword: ''
        }
      }
    },
    created () {
      
    },
    computed: {
    },
    methods: {
      // 提交操作
      handleSubmit () {
        let model = {
          password: '请输入新的密码',
          repassword: '请输入确认密码'
        }
        for (let key in model) {
          if (this.form[key] === '' || this.form[key] === undefined) {
            this.$message.warn(model[key])
            return
          }
        }
        this.$indicator.open()
        this.apiUrl.resetSelfPassword(this.form.password).then(res => {
          if (res.code === 200) {
            this.$message.success(res.message || '修改成功')
          } else {
            this.$message.error(res.message)
          }
        }).finally(() => {
          this.$indicator.close()
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

  .table-box {
    /*批量操作固定左下角样式*/
    position: relative;

    .footer-btn {
      position: absolute;
      bottom: 18px;
      left: 12px;
    }
  }
  .search-item {
    margin: 10px 0;
  }
</style>
